import { Component } from '@angular/core';
import { sliderData } from 'src/app/models';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: false,
})
export class SliderComponent {
  data = sliderData;

  constructor() {}

  ngOnInit(): void {}
}
