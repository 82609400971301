import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AboutComponent,
  ContactComponent,
  FooterComponent,
  HeaderComponent,
  HomeComponent,
  ServicesComponent,
  TechnologiesComponent,
  SliderComponent,
  BannerComponent,
  ExceltoxmlComponent,
} from './components';
import { ReactiveFormsModule } from '@angular/forms';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { SpinnerComponent } from './core';
import { httpInterceptProviders } from './Interceptors';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    ServicesComponent,
    TechnologiesComponent,
    SliderComponent,
    SpinnerComponent,
    BannerComponent,
    ExceltoxmlComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
  ],
  providers: [
    httpInterceptProviders,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
