import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  formData: any = new FormData();
  constructor(private http: HttpClient) {}

  sendMessage(contactForm: UntypedFormGroup) {
    Object.keys(contactForm.value).forEach((el) => {
      this.formData.append(el, contactForm.get(el).value);
    });
    return this.http.post(environment.contactApi, this.formData);
  }

  getResponseMessage(response) {
    switch (response) {
      case 'success':
        return "Thanks for the message! We'll get back to you soon!";
      case 'Unknown Error':
        return 'Oops! An error occurred... Reload the page and try again.';
      default:
        return 'Oops! Something went wrong... Reload the page and try again.';
    }
  }
}
