export const ourServicesData = [
  {
    title: 'Accounting',
    description: `As accounting professionals, most of us have seen businesses
    succeed and fail. By offering our expertise and providing guidance, we can
    help our clients in one or more specific situations.`,
  },
  {
    title: 'Professional Bookkeeping Services',
    description:
      ' Bookkeeping is pivotal for every business and must be conducted in an acurrate and efficient manner.',
  },
  {
    title: 'Accounts Receivable Service for Enhanced Business Cash Flow',
    description: `One of the most considerable assets on a balance sheet is Account
      Receivable, and even though most businesses are aware of its
      significance, but it fails to perform this task efficiently.
      Businesses are not able to allocate their time and resource
      efficiently for accounts receivable.`,
  },
  {
    title: 'Financial Reporting Services',
    description: `Accounting and bookkeeping are a continuous and vital function.
    All the efforts we put this process is reflected in the financial report.
    Financial reports provided a bird-eye view of the overall financial activity.`,
  },
  {
    title: 'GST Services',
    description: `GST Registration, GST Return Filing (GSTR-1, GSTR-3B, GSTR-9, etc.), GST Audit, GST Assessment,
     GST Refund, GST Consultancy, GST Compliance, GST Advisory, GST Litigation.`,
  },
];
