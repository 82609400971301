import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ContactService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  standalone: false,
})
export class ContactComponent {
  title = 'Contact Us';
  contactForm: UntypedFormGroup;
  submitted = false;
  responseMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactService
  ) {
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      mobile: [
        null,
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      message: [null, Validators.required],
      honeypot: '',
    });
  }

  ngOnInit(): void {
    $(document).ready(function () {
      var contentWayPoint = function () {
        var i = 0;
        $('.ftco-animate').waypoint(
          function (direction) {
            if (
              direction === 'down' &&
              !$(this.element).hasClass('ftco-animated')
            ) {
              i++;

              $(this.element).addClass('item-animate');
              setTimeout(function () {
                $('.ftco-animate.item-animate').each(function (k) {
                  var el = $(this);
                  setTimeout(
                    function () {
                      var effect = el.data('animate-effect');
                      if (effect === 'fadeIn') {
                        el.addClass('fadeIn ftco-animated');
                      } else if (effect === 'fadeInLeft') {
                        el.addClass('fadeInLeft ftco-animated');
                      } else if (effect === 'fadeInRight') {
                        el.addClass('fadeInRight ftco-animated');
                      } else {
                        el.addClass('fadeInUp ftco-animated');
                      }
                      el.removeClass('item-animate');
                    },
                    k * 50,
                    'easeInOutExpo'
                  );
                });
              }, 100);
            }
          },
          { offset: '95%' }
        );
      };
      contentWayPoint();
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (
      this.contactForm.invalid &&
      this.contactForm.controls.honeypot.value == ''
    ) {
      return;
    } else {
      this.contactService.sendMessage(this.contactForm).subscribe(
        (response) => {
          this.responseMessage = this.contactService.getResponseMessage(
            response['result']
          );
          this.clearForm();
        },
        (error) => {
          this.responseMessage = this.contactService.getResponseMessage(
            error['statusText']
          );
          this.clearForm();
        }
      );
    }
  }

  clearForm() {
    this.contactForm.reset();
    Object.keys(this.contactForm.controls).forEach((key) => {
      this.contactForm.get(key).setErrors(null);
    });
    setTimeout(() => {
      console.log('hide');
      this.responseMessage = '';
    }, 5000);
  }
}
