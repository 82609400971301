<app-banner [title]="title"></app-banner>

<section class="ftco-section bg-light ftco-no-pt">
  <br />
  <br />
  <br />
  <div class="container">
    <div class="row">
      <div
        class="
          col-md-6 col-lg-3
          d-flex
          services
          align-self-stretch
          px-4
          ftco-animate
        "
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-accounting-1"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">Tally ERP 9/Prime</h3>
            <p>
              Tally is one best accounting software that can integrated with
              other business applications such as Sales, finance, Purchasing,
              Payroll, Inventory, etc.
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          col-md-6 col-lg-3
          d-flex
          services
          align-self-stretch
          px-4
          ftco-animate
        "
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-tax"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">Busy Win</h3>
            <p>
              Busywin is the best financial accounting software and advance
              inventory management.
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          col-md-6 col-lg-3
          d-flex
          services
          align-self-stretch
          px-4
          ftco-animate
        "
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-loan"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">MS Excel</h3>
            <p>
              Microsoft Office Excel was designed to support accounting
              functions such as budgeting, preparing financial statements and
              creating balance sheets.
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          col-md-6 col-lg-3
          d-flex
          services
          align-self-stretch
          px-4
          ftco-animate
        "
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-budget"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">CompuOffice</h3>
            <p>
              Complete automation Software for Professionals, it's help in
              Common client information for all software(s),Return filing
              Management, E-Payment of Challan.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
