export const homeTitleConstant = 'MK Accountify';

export const phoneNumberConstant = '+91 9027844442, 9410827811';

export const emailIdConstant = 'contact@mkaccountify.com';

export const linksData = [
  {
    title: 'Home',
    link: 'home',
  },
  {
    title: 'About',
    link: 'about',
  },
  {
    title: 'Technologies',
    link: 'technologies',
  },
  {
    title: 'Services',
    link: 'services',
  },
  {
    title: 'Contact',
    link: 'contact',
  },
];

export const sliderData = [
  {
    heading1: 'We Help Your Businesses to Grow',
    heading2: 'We Business Grow',
  },
  {
    heading1: 'The Best Business Support',
    heading2: 'We Support Business',
  },
  {
    heading1: 'Expert GST Advice',
    heading2: 'We Give Advice',
  },
  {
    heading1: 'Expert Income Tax Advice',
    heading2: 'We Give Advice',
  },
];
