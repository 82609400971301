<app-banner [title]="title"></app-banner>

<section class="ftco-section ftco-no-pt bg-light">
  <div class="container">
    <div class="row d-flex no-gutters">
      <div class="col-md-6 d-flex">
        <div
          class="
            img img-video
            d-flex
            align-self-stretch align-items-center
            justify-content-center justify-content-md-center
            mb-4 mb-sm-0
          "
          style="background-image: url(assets/images/about.jpg)"
        ></div>
      </div>
      <div class="col-md-6 pl-md-5 py-md-5">
        <div class="heading-section pl-md-4 pt-md-5">
          <span class="subheading">Welcome to MK Accountify</span>
          <h2 class="mb-4">We Are the Best Accounting Firm</h2>
          <p>
            MK Accountify complete fundamental accounting tasks for small and
            medium-sized companies. No certification is required for
            bookkeepers, although they may seek certifications. Bookkeeping
            firms are usually small companies with only a few employees.
            Services provided by bookkeeping firms include accounts receivable,
            accounts payable, bank reconciliation, credit sales reconciliation,
            financial statement preparation and preparation of accounting write
            ups. Additional services offered by bookkeeping firms include
            payroll, small business tax preparation and GST preparation.
          </p>
          <p>
            Tax accounting firms focus on tax preparation and planning for
            companies of all sizes, and also for individuals.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
