import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from './SpinnerInterceptor';

export const httpInterceptProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerInterceptor,
    multi: true,
  },
];
