import { Component } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-exceltoxml',
  templateUrl: './exceltoxml.component.html',
  styleUrl: './exceltoxml.component.scss',
  standalone: false,
})
export class ExceltoxmlComponent {
  title = 'Tally ERP Excel to XML import';
  constructor() {}
}
