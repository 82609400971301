<div class="hero-wrap">
  <div class="home-slider owl-carousel">
    <div *ngFor="let item of data; let i = index">
      <div
        class="slider-item"
        [useCache]="false"
        [style.backgroundImage]="
          'url(assets/images/bg_' + (i + 1) + '.jpg?v=1)'
        "
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="
              row
              no-gutters
              slider-text
              align-items-center
              justify-content-center
            "
          >
            <div class="col-md-8 ftco-animate">
              <div class="text w-100 text-center">
                <h2>{{ item.heading2 }}</h2>
                <h1 class="mb-4">{{ item.heading1 }}</h1>
                <p>
                  <a routerLink="/contact" class="btn btn-white"
                    >Connect with us</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
