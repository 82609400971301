<app-slider></app-slider>

<section class="ftco-section ftco-no-pt bg-light">
  <div class="container">
    <div class="row d-flex no-gutters">
      <div class="col-md-6 d-flex">
        <div
          class="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"
          style="background-image: url(assets/images/about-1.jpg)"
        ></div>
      </div>
      <div class="col-md-6 pl-md-5 py-md-5">
        <div class="heading-section pl-md-4 pt-md-5">
          <span class="subheading">Welcome to Accounting</span>
          <h2 class="mb-4">We Are the Best Accounting Firm</h2>
        </div>
        <div class="services-2 w-100 d-flex">
          <div class="icon d-flex align-items-center justify-content-center">
            <span class="flaticon-accountant"></span>
          </div>
          <div class="text pl-4">
            <h4>Accounting Advisor</h4>
            <p>
              As accounting professionals, most of us have seen businesses
              succeed and fail. By offering our expertise and providing
              guidance, we can help our clients in one or more specific
              situations.
            </p>
          </div>
        </div>
        <div class="services-2 w-100 d-flex">
          <div class="icon d-flex align-items-center justify-content-center">
            <span class="flaticon-wealth"></span>
          </div>
          <div class="text pl-4">
            <h4>Tax Advisor</h4>
            <p>
              A tax advisor is a financial professional who provides advice on
              strategies to minimize taxes owed while staying within the scope
              of the law and regulation.
            </p>
          </div>
        </div>
        <div class="services-2 w-100 d-flex">
          <div class="icon d-flex align-items-center justify-content-center">
            <span class="flaticon-teamwork"></span>
          </div>
          <div class="text pl-4">
            <h4>General Consultancy</h4>
            <p>
              A consultant is someone who has some level of expertise that a
              particular group of people find valuable, and people within that
              group are willing to pay the consultant to access their expertise.
            </p>
          </div>
        </div>
        <div class="services-2 w-100 d-flex">
          <div class="icon d-flex align-items-center justify-content-center">
            <span class="flaticon-accounting"></span>
          </div>
          <div class="text pl-4">
            <h4>Financial Management</h4>
            <p>
              Another set of experts believe that finance is all about cash.
              Since all business transactions involve cash, directly or
              indirectly, finance is concerned with everything done by the
              business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section bg-light ftco-no-pt">
  <div class="container">
    <div class="row">
      <div
        class="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ftco-animate"
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-accounting-1"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">Mission</h3>
            <p>
              Our mission is to provide superior client service and build
              long-term client relationships for reliable business solutions.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ftco-animate"
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-tax"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">Approach</h3>
            <p>
              We are enthusiastic about learning, sharing insights, and using
              the latest technology to help our clients.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ftco-animate"
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-loan"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">Values</h3>
            <p>
              Provide a great work environment and treat each other with
              respect. We work hard to be available when needed, to give the
              best advice.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ftco-animate"
      >
        <div class="d-block">
          <div class="icon d-flex mr-2">
            <span class="flaticon-teamwork"></span>
          </div>
          <div class="media-body">
            <h3 class="heading">Team Work</h3>
            <p>
              We work as a team to be an integral part of our client’s team.
              Without our teamwork, our efficiency would be lost, Every team
              member holds a special place in our firm.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="ftco-section testimony-section bg-light">
  <div class="overlay"></div>
  <div class="container">
    <div class="row justify-content-center pb-5 mb-3">
      <div
        class="
          col-md-7
          heading-section heading-section-white
          text-center
          ftco-animate
        "
      >
        <span class="subheading">Testimonies</span>
        <h2>Happy Clients &amp; Feedbacks</h2>
      </div>
    </div>
    <div class="row ftco-animate">
      <div class="col-md-12">
        <div class="carousel-testimony owl-carousel ftco-owl">
          <div class="item">
            <div class="testimony-wrap py-4">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="fa fa-quote-left"></span>
              </div>
              <div class="text">
                <p class="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <div class="d-flex align-items-center">
                  <div
                    class="user-img"
                    style="background-image: url(assets/images/person_1.jpg)"
                  ></div>
                  <div class="pl-3">
                    <p class="name">Roger Scott</p>
                    <span class="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="fa fa-quote-left"></span>
              </div>
              <div class="text">
                <p class="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <div class="d-flex align-items-center">
                  <div
                    class="user-img"
                    style="background-image: url(assets/images/person_2.jpg)"
                  ></div>
                  <div class="pl-3">
                    <p class="name">Roger Scott</p>
                    <span class="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="fa fa-quote-left"></span>
              </div>
              <div class="text">
                <p class="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <div class="d-flex align-items-center">
                  <div
                    class="user-img"
                    style="background-image: url(assets/images/person_3.jpg)"
                  ></div>
                  <div class="pl-3">
                    <p class="name">Roger Scott</p>
                    <span class="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="fa fa-quote-left"></span>
              </div>
              <div class="text">
                <p class="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <div class="d-flex align-items-center">
                  <div
                    class="user-img"
                    style="background-image: url(assets/images/person_1.jpg)"
                  ></div>
                  <div class="pl-3">
                    <p class="name">Roger Scott</p>
                    <span class="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="fa fa-quote-left"></span>
              </div>
              <div class="text">
                <p class="mb-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
                <div class="d-flex align-items-center">
                  <div
                    class="user-img"
                    style="background-image: url(assets/images/person_2.jpg)"
                  ></div>
                  <div class="pl-3">
                    <p class="name">Roger Scott</p>
                    <span class="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center pb-5 mb-3">
      <div class="col-md-7 heading-section text-center ftco-animate">
        <span class="subheading">News &amp; Blog</span>
        <h2>Latest news and Updates</h2>
      </div>
    </div>
    <div class="row d-flex">
      <div class="col-md-4 d-flex ftco-animate">
        <div class="blog-entry align-self-stretch">
          <a
            href="blog-single.html"
            class="block-20 rounded"
            style="background-image: url('assets/images/image_1.jpg')"
          >
          </a>
          <div class="text p-4">
            <div class="meta mb-2">
              <div><a href="#">March 31, 2020</a></div>
              <div><a href="#">Admin</a></div>
              <div>
                <a href="#" class="meta-chat"
                  ><span class="fa fa-comment"></span> 3</a
                >
              </div>
            </div>
            <h3 class="heading">
              <a href="#"
                >Even the all-powerful Pointing has no control about the blind
                texts</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex ftco-animate">
        <div class="blog-entry align-self-stretch">
          <a
            href="blog-single.html"
            class="block-20 rounded"
            style="background-image: url('assets/images/image_2.jpg')"
          >
          </a>
          <div class="text p-4">
            <div class="meta mb-2">
              <div><a href="#">March 31, 2020</a></div>
              <div><a href="#">Admin</a></div>
              <div>
                <a href="#" class="meta-chat"
                  ><span class="fa fa-comment"></span> 3</a
                >
              </div>
            </div>
            <h3 class="heading">
              <a href="#"
                >Even the all-powerful Pointing has no control about the blind
                texts</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex ftco-animate">
        <div class="blog-entry align-self-stretch">
          <a
            href="blog-single.html"
            class="block-20 rounded"
            style="background-image: url('assets/images/image_3.jpg')"
          >
          </a>
          <div class="text p-4">
            <div class="meta mb-2">
              <div><a href="#">March 31, 2020</a></div>
              <div><a href="#">Admin</a></div>
              <div>
                <a href="#" class="meta-chat"
                  ><span class="fa fa-comment"></span> 3</a
                >
              </div>
            </div>
            <h3 class="heading">
              <a href="#"
                >Even the all-powerful Pointing has no control about the blind
                texts</a
              >
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
